<template>
  <div class="about-company">
    <div class="push-inner">
      <company-address :address="address" />
      <description />
      <contact-info />
      <company-info />
    </div>
    <!--<company-map />-->
    <!--uncomment this when available-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CompanyMap from '~/components/company/about/CompanyMap'
import Description from '~/components/company/about/Description'
import CompanyInfo from '~/components/company/about/CompanyInfo'
import ContactInfo from '~/components/company/about/ContactInfo'
import CompanyAddress from '~/components/company/about/CompanyAddress'

export default {
  name: 'CompanyIdAbout',
  components: {
    CompanyMap,
    Description,
    CompanyInfo,
    ContactInfo,
    CompanyAddress
  },
  computed: {
    ...mapState('company/about', ['address'])
  }
}
</script>
<style src="~/assets/scss/sections/company/about.scss" lang="scss" />
