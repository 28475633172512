<template>
  <div class="col-md-6">
    <div class="info-section">
      <div
        v-loading="pending"
        :class="{ 'edit-info': state === 'edit' }"
        class="group-info">
        <template v-if="state !== 'delete'">
          <h4>{{ label }}</h4>
          <template v-if="state !== 'edit'">
            <div @click="enableEditing">
              <pre>{{ description | descriptionFilter }}</pre>
            </div>
            <template v-if="id !== 'default'">
              <i
                class="edit-icon"
                @click.stop.prevent="enableEditing"/>
              <i
                class="fa fa-trash"
                @click.stop.prevent="enableDeleting"/>
            </template>
          </template>
          <template v-else>
            <div
              v-click-outside="edit"
              class="comp-info-edit"
              @keyup.ctrl.enter.exact="edit">
              <textarea 
                ref="descriptionInput" 
                :value="description"/>
              <div class="save-cancel-button">
                <span 
                  class="checked-icon medium blue" 
                  @click.stop.prevent="edit"/>
                <span 
                  class="removed-icon" 
                  @click.stop.prevent="cancel"/>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div 
            v-click-outside="cancelDelete" 
            class="delete-confirmation">
            Confirm Delete?
            <span 
              class="btn btn-text" 
              @click.stop.prevent="confirmDelete">Yes</span>
            <span
              class="btn btn-text secondary"
              @click.stop.prevent="cancelDelete">No</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    descriptionFilter(description) {
      return description instanceof Array ? description.join(', ') : description
    }
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    description: {
      type: [String, Array],
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      state: null,
      pending: false
    }
  },
  methods: {
    enableEditing() {
      if (this.id === 'default') {
        return
      }
      this.state = 'edit'
      this.$nextTick(() => {
        this.$refs.descriptionInput.select()
      })
    },
    enableDeleting() {
      this.state = 'delete'
    },
    confirmDelete() {
      this.pending = true

      this.onDelete({
        id: this.id
      }).then(() => {
        this.pending = false
      })

      this.state = null
    },
    cancelDelete() {
      this.state = null
    },
    edit() {
      this.pending = true

      this.onEdit({
        id: this.id,
        label: this.label,
        description: this.$refs.descriptionInput.value
      }).then(() => {
        this.pending = false
      })

      this.state = null
    },
    cancel() {
      this.$refs.descriptionInput.value = this.description
      this.state = null
    }
  }
}
</script>
