<template>
  <section class="bdr-bottom">
    <div class="row collapse">
      <div class="col-md-4"><h2>Company Description</h2></div>
      <div
        v-loading="pending"
        class="col-md-8">
        <ContentLoader
          v-if="$actions.pending('company/about/fetchCompanyAbout')"
          :height="15">
          <rect
            x="0"
            y="0"
            width="250"
            height="4"/>
          <rect
            x="0"
            y="10"
            width="200"
            height="4"/>
        </ContentLoader>
        <div v-else>
          <p
            v-if="!editing"
            @click="enableEditing">
            <template>
              <template v-if="description">
                {{ description }}
              </template>
              <template v-else>
                No description available
              </template>
            </template>
            <i
              class="edit-icon"
              @click="enableEditing"/>
          </p>
          <div
            v-click-outside="save"
            v-else
            class="comp-info-edit company-desc">
            <textarea
              ref="descriptionTextArea"
              :value="description"
              @keyup.ctrl.enter.exact="save"/>
            <div class="save-cancel-button">
              <span
                class="checked-icon medium blue"
                @click.stop.prevent="save"/>
              <span
                class="removed-icon"
                @click.stop.prevent="cancel"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ContentLoader } from '~/components/custom/vue-content-loader'

export default {
  components: {
    ContentLoader
  },
  data() {
    return {
      editing: false,
      pending: false
    }
  },
  computed: {
    ...mapState('company/about', {
      description: state => state.description
    })
  },
  methods: {
    ...mapActions('company/about', ['editDescription']),
    enableEditing() {
      this.editing = true
      this.$nextTick(() => {
        this.$refs.descriptionTextArea.select()
      })
    },
    save() {
      if (!this.editing) {
        return
      }
      this.pending = true
      this.editDescription(this.$refs.descriptionTextArea.value).then(() => {
        this.pending = false
      })
      this.editing = false
    },
    cancel() {
      this.$refs.descriptionTextArea.value = this.description
      this.editing = false
    }
  }
}
</script>
