<template>
  <div class="col-md-6">
    <div class="info-section">
      <ContentLoader :height="30">
        <rect
          x="0"
          y="0"
          rx="3"
          ry="3"
          width="50"
          height="8"/>
        <rect
          x="0"
          y="15"
          rx="3"
          ry="3"
          width="120"
          height="10"/>
      </ContentLoader>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from '~/components/custom/vue-content-loader'

export default {
  components: {
    ContentLoader
  }
}
</script>
