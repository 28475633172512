<template>
  <div class="bottom-map-container">
    <div class="map-sidebar">
      <div class="map-sidebar-head"><h2>Location</h2></div>
      <div class="content-listing">
        <div class="company-location">
          <div
            v-for="(location, index) in locations"
            :class="{ active: location === selectedLocation }"
            :key="index"
            class="company-address"
            @click="selectLocation(location)">
            <h3>{{ location.label }}</h3>
            <i class="fa fa-map-marker" />
            <span>{{ location.location.fullAddress }}</span>
            <span>{{ location.count }} Shipments</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-loading="fetching"
      id="map"
      ref="companyMap"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import uuid from 'uuid/v4'
import InfoBubble from '~/utils/google/InfoBubble'

let googleMaps
let GoogleMapsUtils
let gmapConfig

let geolocator

export default {
  data() {
    return {
      cache: {},
      selectedLocation: null,
      fetching: false
    }
  },

  computed: {
    formattedLocations() {
      return this.locations.map(location => ({
        ...location,
        selected: this.selectedLocation === location
      }))
    },

    ...mapState('company/about', {
      locations: state => state.locations
    })
  },

  watch: {
    locations() {
      this.loadMap()
    }
  },

  async mounted() {
    googleMaps = await import('google-maps')
    GoogleMapsUtils = (await import('~/utils/google/googleMaps')).default
    gmapConfig = (await import('~/config/googleMaps')).default

    geolocator = new GoogleMapsUtils()
    this.loadMap()
  },
  destroyed() {
    if (this.infoBubble) {
      this.infoBubble.destroy()
    }
  },

  methods: {
    markAddress(mapId, name, address) {
      this.fetching = true

      const geolocatorOptions = {
        maxRetries: 20,
        retryFn: address =>
          address.substring(address.indexOf(' ') + 1, address.length)
      }

      geolocator
        .locate(address, geolocatorOptions)
        .then(({ location, formattedAddress }) => {
          if (mapId === this.mapId) {
            this.clearMap()

            this.marker = new this.google.maps.Marker({
              map: this.map,
              position: location.geometry.location,
              icon: gmapConfig.marker.icons.pointer
            })

            this.cache[address] = {
              marker: this.marker,
              address: formattedAddress
            }

            this.infoBubble.setContent(
              gmapConfig.tooltip(name, formattedAddress)
            )
            this.infoBubble.open(this.map, this.marker)

            this.map.panTo(this.marker.getPosition())

            this.fetching = false
          }
        })
    },

    clearMap() {
      if (this.marker) this.marker.setMap(null)
      this.marker = null
    },

    selectLocation(location) {
      this.selectedLocation = location
      this.mapId = uuid()
      const mapId = this.mapId
      this.markAddress(mapId, location.label, location.location.fullAddress)
    },

    loadMap() {
      googleMaps.load(google => {
        this.google = google
        this.map = new google.maps.Map(this.$refs.companyMap, {
          ...gmapConfig.map,
          center: { lat: 0, lng: 0 }
        })
        this.infoBubble = new InfoBubble({ map: this.map })

        if (this.locations.length > 0) {
          this.selectLocation(this.locations[0])
        }
      })
    }
  }
}
</script>
