<template>
  <section class="bdr-bottom">
    <div class="row collapse">
      <div class="col-md-4"><h2>Other Info</h2></div>
      <div class="col-md-8">
        <div class="row collapse">
          <ContentLoader
            v-if="$actions.pending('company/about/fetchCompanyAbout')"
            :height="60">
            <rect
              x="0"
              y="4"
              width="30"
              height="4"/>
            <rect
              x="0"
              y="11"
              width="90"
              height="4"/>
            <rect
              x="200"
              y="4"
              width="30"
              height="4"/>
            <rect
              x="200"
              y="11"
              width="90"
              height="4"/>
            <rect
              x="0"
              y="34"
              width="30"
              height="4"/>
            <rect
              x="0"
              y="41"
              width="90"
              height="4"/>
            <rect
              x="200"
              y="34"
              width="30"
              height="4"/>
            <rect
              x="200"
              y="41"
              width="90"
              height="4"/>
          </ContentLoader>
          <template v-else>
            <info-item
              v-for="(info, index) in otherInfo"
              :key="info.label + index"
              :id="info.id"
              :label="info.label"
              :description="info.description"
              :on-delete="deleteOtherInfo"
              :on-edit="editOtherInfo"/>
            <temp-item v-if="adding" />
            <div
              v-if="!addEnabled && otherInfo.length < 1 && !adding"
              class="col-md-12 collapse">
              <p class="not-editable">No current available information!</p>
            </div>
            <add-info
              v-if="addEnabled"
              heading="Add other info"
              @save="onAdd"
              @cancel="addEnabled = false"/>
            <div class="add-info">
              <span
                v-if="!addEnabled"
                class="brn btn-text"
                @click="addEnabled = true">Add Info</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ContentLoader } from '~/components/custom/vue-content-loader'
import ErrorNotificationMessage from '~/components/common/ErrorNotificationMessage'
import AddInfo from './AddInfo'
import TempItem from './TempItem'
import InfoItem from './InfoItem'

export default {
  components: {
    AddInfo,
    TempItem,
    InfoItem,
    ContentLoader
  },
  data() {
    return {
      addEnabled: false,
      adding: false,
      notificationInstance: null
    }
  },
  computed: {
    ...mapState('company/about', {
      otherInfo: state => state.otherInfo
    })
  },
  methods: {
    ...mapActions('company/about', [
      'addOtherInfo',
      'editOtherInfo',
      'deleteOtherInfo'
    ]),
    onAdd({ label, description }) {
      const h = this.$createElement

      if (this.notificationInstance) {
        this.notificationInstance.close()
      }

      if (!label || !description) {
        this.notificationInstance = this.$notify.error({
          message: h(ErrorNotificationMessage, {
            props: { message: 'Title and Description is required' }
          }),
          customClass: 'as-error'
        })

        return
      }

      if (
        this.otherInfo
          .map(t => t.label.toLowerCase())
          .indexOf(label.toLowerCase()) > -1
      ) {
        this.notificationInstance = this.$notify.error({
          message: h(ErrorNotificationMessage, {
            props: { message: 'Information already exists' }
          }),
          customClass: 'as-error'
        })

        return
      }

      this.adding = true
      this.addOtherInfo({
        label,
        description
      })
        .then(() => {
          this.adding = false
        })
        .catch(() => {
          this.adding = false
        })

      this.addEnabled = false
    }
  }
}
</script>
