<template>
  <div class="col-md-6">
    <div class="info-section input-section">
      <div class="group-info">
        <div class="form">
          <h2>{{ heading }}</h2>
          <input
            v-model="label"
            class="full-width"
            placeholder="Enter Title"
            @keyup.ctrl.enter.exact="onSave">
          <textarea
            v-model="description"
            class="full-width"
            placeholder="Enter Description"
            @keyup.ctrl.enter.exact="onSave"/>
          <button 
            class="btn btn-text secondary" 
            @click.prevent="onCancel">
            Cancel
          </button>
          <button 
            class="btn btn-text" 
            @click.prevent="onSave">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      label: '',
      description: ''
    }
  },
  methods: {
    onCancel() {
      this.clear()

      this.$emit('cancel')
    },
    onSave() {
      this.$emit('save', {
        label: this.label,
        description: this.description
      })
    },
    clear() {
      this.label = ''
      this.description = ''
    }
  }
}
</script>
